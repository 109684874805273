import SwiperCore, { Autoplay, Pagination } from 'swiper';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { createApp, ref } from "vue";

SwiperCore.use([Autoplay, Pagination]);

const imageCarouselElement = document.querySelector('[data-tag="image-carousel"]');
if (imageCarouselElement) {
  document.addEventListener('DOMContentLoaded', () => {
    const app = createApp({
      components: {
        Swiper,
        SwiperSlide,
      },
      setup() {
        const swiper = ref(null);

        const onSwiper = (swiperInstance) => {
          swiper.value = swiperInstance;
        };

        const onSlideChange = () => {};

        return {
          onSwiper,
          onSlideChange,
          swiper: swiper.value,
        };
      },
    });

    app.mount('#image-carousel');
  })
};
